import React, {createContext, useEffect, useState} from "react";

const initGroupState = {
  "My Family": {
    host: "",
    format: "leader",
    members: new Set(),
    mealQueue: [],
    selected: new Set(),
    pool: new Set(),
    votes: new Set(),
    history: [],
    groceryData: "",
    haves: [],
    adds: [],
    newAmounts: [],
    servings: 4,
  },
};

// groups informations will be stored in the local storage of the client browser in order to keep the user's groups after a page refresh
const localState = JSON.parse(sessionStorage.getItem("defaultGroup"));
const GroupContext = createContext();

function GroupProvider(props) {
  const [defaultGroup, setDefaultGroup] = useState(localState || initGroupState);

  useEffect(() => {
    sessionStorage.setItem("defaultGroup", JSON.stringify(defaultGroup));
  }, [defaultGroup]);

  return <GroupContext.Provider value={{defaultGroup, setDefaultGroup}}>{props.children}</GroupContext.Provider>;
}

export {GroupContext, GroupProvider};
