import {withStyles} from "@material-ui/core";

const styles = (theme) => ({
  "@global": {
    "*": {
      scrollbarWidth: "none" /* Firefox */,
      scrollBehavior: "smooth",
      fontFamily: theme.typography.body1.fontFamily,
    },
    "*::-webkit-scrollbar": {
      /* Hide scrollbar for Chrome, Safari and Opera */
      display: "none",
    },
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    "*:focus": {
      outline: 0,
    },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".listItemLeftPadding": {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      "@media (max-width:  420px)": {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    ".container": {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 800,
      },
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
    },
    ".container-lg": {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        maxWidth: theme.breakpoints.values.sm,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 1024,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1580,
      },
    },
    ".container-lg-mg": {
      width: "90%",
      marginRight: "5%",
      marginLeft: "5%",
      [theme.breakpoints.up("sm")]: {
        maxWidth: theme.breakpoints.values.sm,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 1024,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1580,
      },
    },
    ".container-fluid": {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        maxWidth: theme.breakpoints.values.sm,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 1024,
      },
    },
    ".loggedin-mg-bottom": {
      marginBottom: `${theme.spacing(9)}px !important`,
    },
    ".lg-mg-top": {
      marginTop: `${theme.spacing(7)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginTop: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.spacing(5)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: `${theme.spacing(4)}px !important`,
      },
    },
    ".lg-mg-bottom": {
      marginBottom: `${theme.spacing(7)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(5)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(4)}px !important`,
      },
    },
    ".lg-p-top": {
      paddingTop: `${theme.spacing(7)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(5)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(4)}px !important`,
      },
    },
    ".sm-p-top": {
      paddingTop: `${theme.spacing(5)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(4)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(3)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(2)}px !important`,
      },
    },
    ".button-padding": {
      height: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.up("xs")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    ".link": {
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.complex,
        easing: theme.transitions.easing.easeInOut,
      }),
      cursor: "pointer",
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.dark,
      },
      "&:focus": {
        color: theme.palette.primary.dark,
      },
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, {withTheme: true})(globalStyles);
