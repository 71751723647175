import React, {Fragment, Suspense, lazy} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {LastLocationProvider} from "react-router-last-location";
import {MuiThemeProvider, CssBaseline} from "@material-ui/core";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import {UserProvider} from "./shared/context/UserContext";
import {GroupProvider} from "./shared/context/GroupContext";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
  return (
    <UserProvider>
      <GroupProvider>
        <BrowserRouter>
          <LastLocationProvider>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyles />
              <Suspense fallback={<Fragment />}>
                <Switch>
                  <Route path="/c">
                    <LoggedInComponent />
                  </Route>
                  <Route>
                    <LoggedOutComponent />
                  </Route>
                </Switch>
              </Suspense>
            </MuiThemeProvider>
          </LastLocationProvider>
        </BrowserRouter>
      </GroupProvider>
    </UserProvider>
  );
}

export default App;
