import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";

// colors
const primary = "#104464"; //blue "#0d4566"
const secondary = "#f2cc8f"; //deep champagne
const success = "#2ac96c"; //green
const white = "#ffffff";
const eggshell = "rgba(244, 241, 222, .6)";
const eggshellSolid = "rgba(244, 241, 222, 1)";
const greenSheen = "#81b29a";
const terraCotta = "#e07a5f";
const golden = "#d4af37";
const silver = "#b5b5bd";
const bronze = "#a97142";
const babyBlue = "#d6e2f4";
const grey = "rgba(0, 0, 0, 0.54)";
const greyBorder = "rgba(0, 0, 0, 0.23)";
const lightPrimary = "rgba(13, 69, 102, .6)";
const lightSecondary = "rgba(242, 204, 143, .6)";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";
const clear = "rgba(0, 0, 0, 0)";
const black = "rgba(0, 0, 0, 1)";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 1024;
//const md = 960;
const sm = 640;
const xs = 0;

// spacing
const spacing = 8;

// fonts
//const fontNunito = "'Nunito', sans-serif";
const fontRoboto = "'Roboto', sans-serif";
const fontCrimson = "'Crimson', serif";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      light: lightPrimary,
    },
    secondary: {
      main: babyBlue,
      //light: lightSecondary,
    },
    success: {
      main: success,
    },
    common: {
      babyBlue,
      black,
      bronze,
      clear,
      eggshell,
      eggshellSolid,
      golden,
      greenSheen,
      grey,
      greyBorder,
      lightPrimary,
      silver,
      terraCotta,
      white,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        touchAction: "none",
        zIndex: 1000,
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiPopover: {
      paper: {
        padding: spacing,
      },
      root: {
        backgroundColor: "rgba(255, 255, 255, .5)",
      },
    },
    /*MuiCssBaseline: {
      "@global": {
        body: {
          backgroundAttachment: "fixed",
          backgroundImage:
            "linear-gradient(to bottom, rgba(255, 255, 255, .90) 80%, rgba(255, 255, 255, .7)), url(" +
            backgroundImg +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
        },
      },
    },*/
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    brand: {
      fontFamily: fontCrimson,
      fontWeight: 600,
      fontSize: "1.7rem",
    },
    h1: {
      fontFamily: fontRoboto,
    },
    h2: {
      fontFamily: fontRoboto,
    },
    h3: {
      fontFamily: fontRoboto,
    },
    h4: {
      fontFamily: fontRoboto,
    },
    h5: {
      fontFamily: fontRoboto,
      fontWeight: 600,
    },
    h6: {
      fontFamily: fontRoboto,
    },
    body1: {
      fontFamily: fontRoboto,
    },
    body2: {
      fontFamily: fontRoboto,
    },
    subtitle1: {
      fontFamily: fontRoboto,
      fontSize: ".75rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: fontRoboto,
      fontWeight: 400,
    },
    button: {
      fontFamily: fontRoboto,
      textTransform: "none",
    },
  },
});

export default responsiveFontSizes(theme);
